import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import {
  ObjednavkyOstatneDetailUrl,
  ObjednavkyOstatneUrl,
  VypisDokladuHTMLObjOstUrl,
  menuAkciaUrl,
  objMenuAkciaUrl,
} from "../../features/urlHelper";
import {
  objednavkyOstatneTable,
  loadingObjednavkyOstatneTable,
  objednavkyOstatneTableData,
} from "./Table/redux/tableObjednavkyOstatneSlice";
import swal from "sweetalert";
import Objednavky_ostatne_objednavka from "./../Objednavky_ostatne_objednavka";
import { useHistory } from "react-router";
import Delete from "../../components/Delete";
import { errorHandleDialog } from "./../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import {
  loadingObjednavkyOstatneObjednavkaTable,
  objednavkyOstatneObjednavkaTable,
  setButton,
  setCis_obj,
  setDate,
  setTitle,
  setVolba,
} from "../Objednavky_ostatne_objednavka/Table/redux/ObjednavkyOstatneObjednavkaSlice";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { modal, modalData } from "../../components/Modaly/redux/modalSlice";
import { setCis_objed } from "../Objednavky_BB_objednavka/Table/redux/ObjednavkyBBObjednavkaSlice";

const options = [{ label: "test", value: "test" }];

const App = ({ className, dataset, backStep }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const objednavkyOstatneTabledata = useSelector(objednavkyOstatneTableData);

  const [detail_visible, setDetail_visible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [params_to_menu, setParams_to_menu] = React.useState(null);

  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState("");

  const open = Boolean(anchorEl);

  function handleClickListItem(event, id) {
    //console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    setCurrentRow(id);
  }

  const handleMenuItemClick = (event, value, params) => {
    setAnchorEl(null);
    menu_akcia(
      value,
      params.row.cis_objed,
      params.row.datum,
      params.row.korekcia
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  window.addEventListener("beforeunload", function (e) {
    setDetail_visible(false);

    return null;
  });

  function getWidth(width) {
    if (width > 2500) {
      return width - 388;
    } else if (width < 2500 && width > 767) {
      return width - 144;
    } else {
      return width - 24;
    }
  }

  async function menu_akcia(akcia, cis_objed, datum, zmena) {
    await axios
      .post(
        objMenuAkciaUrl,
        {
          menu_akcia: akcia,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          cis_objed: cis_objed,
          datum: datum,
          zmena: zmena,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        //console.log(response);
        if (akcia === "Odeslání do WMS znova" || akcia === "Odeslání do WMS") {
          dispatch(objednavkyOstatneTable(response.data));
        } else if (
          akcia === "Stornovat ve WMS" ||
          akcia === "Testovacia volba odoslane s fajkou" ||
          akcia === "Testovacia volba vychystáva se"
        ) {
          dispatch(objednavkyOstatneTable(response.data.data));
        }
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function detail(params) {
    await axios
      .post(
        ObjednavkyOstatneDetailUrl,
        { id: params.row.id },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        //console.log(response);
        dispatch(objednavkyOstatneObjednavkaTable(response.data.polozky));
        dispatch(setButton("Ok"));
        dispatch(setTitle("Detail objednávky - " + response.data.cis_objed));
        dispatch(setDate(response.data.datum));
        dispatch(setVolba("detail"));
        dispatch(setCis_obj(params.row.cis_objed));
        setDetail_visible(true);
        
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function ma_pravo_mazat(params) {
    console.log(params);
    if (params.row.wms_stav === 2) {
      if (params.row.wms_text === "Vyřizuje se") {
        return false;
      }
      return true;
    }
    return false;
  }

  const columns = [
    {
      field: "detail",
      headerName: "",
      maxWidth: 35,
      minWidth: 35,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        //console.log("params edit", params);
        return (
          <div>
            <div
              title="Detail"
              id={params.row.id}
              className={styles.iconsis}
              onClick={(e) => {
                handleClickListItem(e, params.row.id);
                setParams_to_menu(params);
              }}
            >
              <div className={styles.iconY}>
                <i class="fa fa-bars"></i>
              </div>
            </div>
            <div>
              <Menu
                id={"lock-menu"}
                anchorEl={anchorEl}
                open={open && params.row.id === currentRow}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {params.row.menu.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    onClick={(event) => {
                      handleMenuItemClick(event, option.value, params_to_menu);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        );
      },
    },
    {
      field: "id",
      hideable: false,
      headerName: "Id",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "odoslane",
      hideable: false,
      headerName: "Datum objednání",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.2,
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueFormatter: (params) => {
        return moment(new Date(params?.value)).format("D.M.YYYY HH:mm");
      },
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {moment(new Date(params?.value)).format("D.M.YYYY HH:mm")}
          </div>
        );
      },
    },
    {
      field: "cis_objed",
      hideable: false,
      headerName: "Číslo objednávky",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.25,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "x__adduser",
      hideable: false,
      headerName: "Vytvořil",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.15,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "datum",
      hideable: false,
      headerName: "Datum závozu",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.2,
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueFormatter: (params) => {
        return moment(new Date(params?.value)).format("D.M.YYYY");
      },
      renderCell: (params) => {
        let date = moment(params?.value).format("DD.MM.YYYY");
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {date}
          </div>
        );
      },
    },
    // {
    //   field: "doklad",
    //   hideable: false,
    //   headerName: "Doklad",
    //   width:
    //     (getWidth(window.innerWidth) -
    //       (logindata.UserData.admin || logindata.UserData.admin_obj
    //         ? 125
    //         : 90)) *
    //     0.1,
    //   headerAlign: "center",
    //   headerAlign: "start",
    //   sortable: false,
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //       <button
    //         onClick={async () => {
    //           //console.log(params)
    //           await axios
    //             .post(
    //               VypisDokladuHTMLObjOstUrl,
    //               {
    //                 doklad: params.row.cis_objed,
    //               },
    //               {
    //                 headers: { Authorization: "Bearer " + logindata.Token },
    //               }
    //             )
    //             .then(function (response) {
    //               console.log(response);
    //               if (response.data.html !== null) {
    //                 dispatch(
    //                   modal({
    //                     data: {
    //                       ...modaldata,
    //                       ZobrazHtml: true,
    //                       HtmlData: response.data.html,
    //                       ZobrazHtml_print: response.data.print,
    //                       ZobrazHtml_text: response.data.text,
    //                     },
    //                   })
    //                 );
    //               }
    //             })
    //             .catch(function (error) {
    //               errorHandleDialog(error.response, navigate, dispatch);
    //             });
    //         }}
    //         className={styles.polozka_doklad}
    //       >
    //         <div className={styles.icon_red}>
    //           <i class="fa fa-file-pdf-o"></i>
    //         </div>

    //         <div>Doklad</div>
    //       </button>
    //     );
    //   },
    // },
    {
      field: "text",
      hideable: false,
      headerName: "Text",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.3,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "wms_text",
      hideable: false,
      headerName: "Stav",
      width:
        (getWidth(window.innerWidth) -
          (logindata.UserData.admin || logindata.UserData.admin_obj
            ? 125
            : 90)) *
        0.2,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "detail2",
      hideable: false,
      headerName: "",
      maxWidth: 70,
      minWidth: 70,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.icons}>
            <div
              className={styles.iconY}
              title="Editace"
              onClick={() => {
                dispatch(loadingObjednavkyOstatneTable());
                detail(params);
              }}
            >
              <i class="fa fa-eye"></i>
            </div>
            <div
              onClick={() => {
                let date = moment(new Date(params.row.datum)).format("D.M.YYYY");
                if (ma_pravo_mazat(params)) {
                  swal({
                    content: {
                      element: "div",
                      attributes: {
                        innerHTML:  `<<div style="font-weight: 300; color: red;">  <div>Opravdu chcete stornovat objednávku</div><div> ${params.row.cis_objed} ze dne ${date}?</div></div>`,
                      },
                    },
                    buttons: ["Ne ", "Ano"],
                    icon: "info",
                    title: "Upozornění!",
                  }).then(async (will) => {
                    if (will) {
                      setAnchorEl(null);
                      menu_akcia(
                        "Stornovat ve WMS",
                        params.row.cis_objed,
                        params.row.datum,
                        params.row.korekcia
                      );
                    }
                  });
                }
              }}
              title="Mazání"
              className={
                !ma_pravo_mazat(params) ? styles.iconDis : styles.iconR
              }
            >
              <i class="fa fa-trash-o"></i>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(loadingObjednavkyOstatneTable());
    dispatch(loadingObjednavkyOstatneObjednavkaTable());
    result();
  }, []);

  async function result() {
    await axios
      .post(
        ObjednavkyOstatneUrl,
        {
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        dispatch(objednavkyOstatneTable(response.data));
        dispatch(objednavkyOstatneObjednavkaTable([]));

        const array = [];

        if (logindata.UserData.vybrane_stredisko !== undefined) {
          for (var str of logindata.UserData.vyber_stredisko_zoznam) {
            array.push({ value: str.kod, label: str.kod + " - " + str.nazov });
            if (str.kod === logindata.UserData.vybrane_stredisko.kod) {
              setValue({ value: str.kod, label: str.kod + " - " + str.nazov });
            }
          }
        } else {
          for (var odb of logindata.UserData.vyber_ico_zoznam) {
            array.push({
              value: odb.ico,
              label: odb.ico + " - " + odb.odberatel,
            });
            if (odb.ico === logindata.UserData.ico) {
              setValue({
                value: logindata.UserData.ico,
                label: odb.ico + " - " + odb.odberatel,
              });
            }
          }
        }
        setOptions(array);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function result_zmena(stredisko, ico) {
    //console.log(stredisko)
    await axios
      .post(
        ObjednavkyOstatneUrl,
        {
          stredisko: stredisko !== null ? stredisko : null,
          ico: ico !== null ? ico : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        dispatch(objednavkyOstatneTable(response.data));
        dispatch(objednavkyOstatneObjednavkaTable([]));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <div className={styles.card}>
      <div
        style={{
          height: window.innerHeight - (window.innerWidth < 767 ? 74 : 130),
        }}
      >
        {detail_visible ? (
          <Objednavky_ostatne_objednavka
            setDetail_visible={setDetail_visible}
            result={result}
          />
        ) : (
          <>
            <div className={styles.nadpis}>
              <div>Přehled objednávek</div>
            </div>
            <div className={styles.header}>
              {/* <div className={styles.addNew}>
                {objednavkyOstatneTabledata.loading ? (
                  <></>
                ) : (
                  <button
                    // onClick={async () => {
                    //   dispatch(loadingObjednavkyOstatneObjednavkaTable());
                    //   dispatch(objednavkyOstatneObjednavkaTable([]));
                    //   dispatch(setButton("Odeslat objednávku"));
                    //   dispatch(setTitle("Nová objednávka"));
                    //   dispatch(setDate(moment()._d.toISOString()));
                    //   dispatch(setVolba("new"));


                    // }}
                    className={styles.iconDis}
                  >
                    <i class="fa fa-shopping-cart"></i>
                    <div>Přidat objednávku</div>
                  </button>
                )}
              </div> */}
            </div>
            <Table
              columns={columns}
              options={options}
              value={value}
              setValue={setValue}
              result_zmena={result_zmena}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default App;
