import Card from "../../../components/Card";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  nakupTable,
  nakupTableData,
  loadingNakupTable,
} from "./Table/redux/tableNakupSlice";
import { loginData } from "../../SignIn/redux/loginSlice";
import { modalData, modal } from "../../../components/Modaly/redux/modalSlice";
import Dropzone from "../../../components/DropZone";

const App = ({
  className,
  setZaplaceno,
  zaplaceno,
  setDodlist,
  dodList,
  myFiles,
  setMyFiles,
}) => {
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const nakupdata = useSelector(nakupTableData);
  const modaldata = useSelector(modalData);

  return (
    <>
      <div className={styles.upperContent2}>
        <div className={styles.upperContentInside}>
          <div className={cn(styles.avatar)}>
            <img src={"/images/content/dokument.png"} alt="Avatar" />
          </div>

          <div className={styles.text_inputik}>
            <div className={styles.text}>Dodací list</div>
            <input
              name="dodList"
              value={dodList}
              onClick={() => {
                document.getElementsByName("dodList")[0].select();
              }}
              onChange={(e) => {
                setDodlist(e.target.value);
              }}
              className={styles.inputik}
            ></input>
          </div>
          {/* <div className={styles.upperContentInside}>
          <div className={cn(styles.avatar)}>
            <img src={"/images/content/dokument.png"} alt="Avatar" />
          </div>
          <div className={styles.text_inputik}>
            <div className={styles.text}>Zaplaceno (vč DPH)</div>
            <input
              name="zaplaceno"
              type={"number"}
              value={zaplaceno}
              onClick={() => {
                document.getElementsByName("zaplaceno")[0].select();
              }}
              onChange={(e) => {
                setZaplaceno(e.target.value);
              }}
              className={styles.inputik}
            ></input>
          </div>

          <div className={styles.text}>
            {logindata.UserData.vybrane_stredisko.mena}
          </div>
        </div> */}
        </div>
        <div className={styles.upperContentInside}>
          <div style={{ width: window.innerWidth > 767 ? 350 : 150 }}>
            <Dropzone
              text={"Vložte fotky"}
              onChange={(e)=>setMyFiles(e)}
              // myFiles={myFiles}
              // setMyFiles={setMyFiles}
            />
          </div>
        </div>
      </div>

      <Table className={styles.table} zaplaceno={zaplaceno} />
    </>
  );
};
export default App;
