import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useSelector, useDispatch } from "react-redux";
import { login, loginData } from "./../../../screens/SignIn/redux/loginSlice";
import { ClickAwayListener } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import { zmenaStrediskaUrl } from "../../../features/urlHelper";
import { errorHandleDialog } from "../../../features/errorDialog";
import { useHistory } from "react-router";
import useDarkMode from "@fisch0920/use-dark-mode";

const User = ({ className, ldata, setLdata }) => {
  const [visible, setVisible] = useState(false);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const dispatch = useDispatch();
  const navigate = useHistory();
  const darkMode = useDarkMode(false);
  const items = [
    {
      menu: [
        logindata.UserData.vyber_stredisko && {
          title: "Změna střediska",
          url: "/moduly_vyber_stredisko_zoznam",
        },
        logindata.UserData.vyber_ico && {
          title: "Změna odběratele",
          url: "/moduly_vyber_ico_zoznam",
        },
        !logindata.UserData.domainuser && {
          title: "Změna hesla",
          url: "/zmenaHesla",
        },
        (logindata.UserData.admin ||
          logindata.UserData.admin_bb ||
          logindata.UserData.admin_croco ||
          logindata.UserData.admin_obj) && {
          title: "Seznam uživatelů",
          url: "/users",
        },
        logindata.UserData.admin && {
          title: "Seznam aktivních uživatelů",
          url: "/users_activity",
        },
        //nepoužíva sa
        // logindata.UserData.vyber_ico && {
        //   title: "Seznam B2B uživatelů",
        //   url: "/users_ico",
        // },

        {
          title: "Odhlášení",
          url: "/",
        },
      ].filter(function (x) {
        return x;
      }),
    },
  ];

  return (
    <ClickAwayListener onClickAway={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <div className={styles.popisUsera}>
          {window.innerWidth > 767 ? (
            <>
              {ldata.UserData !== undefined &&
              ldata.UserData.vybrane_stredisko !== undefined ? (
                <div className={styles.popisUsera_stredisko}>
                  <div>{ldata.UserData.vybrane_stredisko.kod}</div>
                  <Select
                    name={"headerSelect"}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 8,
                      colors: {
                        ...theme.colors,
                        primary25: "#FAEECB ",
                        primary50: "#FAEECB ",
                        primary: "#F1B600",
                      },
                    })}
                    isDisabled={ldata.UserData.HeaderOptions.length === 0}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderRadius: 3,
                        backgroundColor: !darkMode.value ? "white" : "#262b30",
                      }),
                      option: (base, state) => ({
                        ...base,
                        borderRadius: 3,
                        color: "#6F767E",
                      }),
                      singleValue: (base, state) => ({
                        ...base,
                        borderRadius: 3,
                        color: "#82878c",
                      }),
                      menu: (base, state) => ({
                        ...base,
                        borderRadius: 0,
                        marginTop: 0,
                        backgroundColor: !darkMode.value ? "white" : "#262b30",
                      }),
                    }}
                    classNamePrefix={styles.select}
                    className={styles.select}
                    defaultValue={{
                      value: ldata.UserData.vybrane_stredisko.kod,
                      label: ldata.UserData.vybrane_stredisko.nazov + "(" + ldata.UserData.vybrane_stredisko.kod + ")",
                    }}
                    isClearable={false}
                    isSearchable={false}
                    options={ldata.UserData.HeaderOptions}
                    onChange={(e) => {
                      //console.log(e)
                      axios
                        .post(
                          zmenaStrediskaUrl + e.value,
                          {},
                          {
                            headers: {
                              Authorization: "Bearer " + logindata.Token,
                            },
                          }
                        )
                        .then(function (response) {
                          let data = { ...response.data };
                          let userdata = { ...response.data.UserData };
                          let HeaderOptions =
                            response.data.UserData.vyber_stredisko_zoznam.map(
                              (option) => {
                                return {
                                  value: option.kod,
                                  label: option.nazov,
                                };
                              }
                            );

                          let userData = {
                            ...userdata,
                            moduly: response.data.UserData.strediska,
                            HeaderOptions: HeaderOptions,
                          };

                          //console.log(HeaderOptions)

                          data.UserData = userData;

                          //dispatch(login({ data: data }));

                          window.sessionStorage.setItem(
                            "logindata",
                            JSON.stringify(data)
                          );

                          setLdata(data);

                          window.location.reload();
                        })
                        .catch(function (error) {
                          errorHandleDialog(error.response, navigate, dispatch);
                        });
                    }}
                  />
                  {/* <div>{ldata.UserData.vybrane_stredisko.nazov}</div> */}
                </div>
              ) : (
                <div className={styles.popisUsera_stredisko}>
                  <div>
                    {logindata.UserData !== undefined && logindata.UserData.ico}
                  </div>
                  <div>
                    <Select
                      name={"headerSelect"}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: "#FAEECB ",
                          primary50: "#FAEECB ",
                          primary: "#F1B600",
                        },
                      })}
                      isDisabled={logindata.UserData.HeaderOptions.length === 0}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderRadius: 3,
                          backgroundColor: !darkMode.value
                            ? "white"
                            : "#262b30",
                        }),
                        option: (base, state) => ({
                          ...base,
                          borderRadius: 3,
                          color: "#6F767E",
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          borderRadius: 3,
                          color: "#82878c",
                        }),
                        menu: (base, state) => ({
                          ...base,
                          borderRadius: 0,
                          marginTop: 0,
                          backgroundColor: !darkMode.value
                            ? "white"
                            : "#262b30",
                        }),
                      }}
                      className={styles.select}
                      defaultValue={{
                        value: logindata.UserData.ico,
                        label: logindata.UserData.nazov_ico,
                        label: logindata.UserData.nazov_ico + "(" + logindata.UserData.ico + ")",
                      }}
                      isClearable={false}
                      isSearchable={false}
                      options={logindata.UserData.HeaderOptions}
                      onChange={(e) => {
                        console.log(logindata.UserData.HeaderOptions.length);
                        let data = { ...logindata };
                        let userData = { ...logindata.UserData };
                        userData.ico = e.value;
                        userData.nazov_ico = e.label;
                        data.UserData = userData;
                        window.sessionStorage.setItem(
                          "logindata",
                          JSON.stringify(data)
                        );
                        //window.location.reload();
                      }}
                    />
                    {/* {ldata.UserData !== undefined && ldata.UserData.nazov_ico} */}
                  </div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>

        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {/* <img src="/images/content/avatar-4.jpg" alt="Avatar" /> */}
          <div className={styles.username}>
            <div>
              {logindata.UserData.username === null ||
              logindata.UserData.username === "" ||
              logindata.UserData.username === undefined
                ? ""
                : logindata.UserData.username.slice(0, 1).toUpperCase()}
            </div>
          </div>
        </button>
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.left_site}>
              <div className={styles.username}>
                <div>
                  {logindata.UserData.username === null ||
                  logindata.UserData.username === "" ||
                  logindata.UserData.username === undefined
                    ? ""
                    : logindata.UserData.username.slice(0, 1).toUpperCase()}
                </div>
              </div>
            </div>
            <div className={styles.right_site}>
              <div>
                {logindata.UserData.username}
                {logindata.UserData.fullName === null ||
                logindata.UserData.fullName === "" ||
                logindata.UserData.fullName === undefined
                  ? ""
                  : "/"}
                {logindata.UserData.fullName}
              </div>
              <div>
                {logindata.UserData.vybrane_stredisko !== undefined &&
                  logindata.UserData.vybrane_stredisko.kod}
              </div>
              <div>
                {logindata.UserData.ico !== undefined && logindata.UserData.ico}
              </div>
              {logindata.UserData.skupina === null ||
              logindata.UserData.skupina === "" ||
              logindata.UserData.skupina === undefined ? (
                <div></div>
              ) : (
                <div>G: {logindata.UserData.skupina}</div>
              )}
              {logindata.UserData.vybrane_stredisko === undefined ? (
                <div></div>
              ) : (
                <div>{logindata.UserData.vybrane_stredisko.nazov}</div>
              )}
              {logindata.UserData.nazov_ico === undefined ? (
                <div></div>
              ) : (
                <div>{logindata.UserData.nazov_ico}</div>
              )}
            </div>
          </div>

          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    activeClassName={styles.active}
                    to={x.url}
                    onClick={() => {
                      if (x.title === "Odhlášení") {
                        //dispatch(login({ data: {} }));
                        window.sessionStorage.setItem(
                          "logindata",
                          JSON.stringify({})
                        );
                      }
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => {
                      //console.log("klikol som tu :D");
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default User;
