
//const serverUrl = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_PROD_URL}` : `${process.env.REACT_APP_PROD_URL}`;
const serverUrl = getUrl()
export function getUrl() {

    // TODO Matej
    // pre produkcnu verziu nesmie byt a skor to riesit cez podmienku
    //if (process.env.NODE_ENV === "production")
    //return process.env.REACT_APP_PROD_URL
    if (window.location.hostname === "marvin.crocodille.com") {
        return process.env.REACT_APP_PROD3_URL
    }
    if (window.location.hostname === "marvin.ad.croco.cz") {
        return process.env.REACT_APP_PROD2_URL
    }
    if (window.location.hostname === "marvintest.ad.croco.cz") {
        return process.env.REACT_APP_PROD4_URL
    }
    if (window.location.hostname === "erp.iist.inver.sk") {
        return process.env.REACT_APP_PROD_URL
    }
    if (window.location.hostname === "localhost" || window.location.hostname.slice(0,4) === "172.") {
        return process.env.REACT_APP_DEV_URL
    }
    if (window.location.hostname.slice(0,3) === "10." || window.location.hostname.slice(0,4) === "192.") {
        return process.env.REACT_APP_PROD_URL
    }
    return window.location.protocol + "//" + window.location.hostname + "/"
}

export const loginUrl = serverUrl + `api/user/login`;

export const soutezUrl = serverUrl + `api/bberp/dashboard/soutez/`;

export const prehladUrl = serverUrl + `api/bberp/dashboard/prehlad/`;

export const commentsUrl = serverUrl + `api/bberp/dashboard/feedbacks/`;

export const zamestnanciUrl = serverUrl + `api/bberp/dashboard/zamestnanci/`;

export const zmenaStrediskaUrl = serverUrl + `api/user/zmenaStrediska/`;

export const pocasieUrl = serverUrl + `api/pocasie/predpoved/`;

export const cennikUrl = serverUrl + `api/bberp/sklad/cennik/`;

export const pobockyUrl = serverUrl + `api/bberp/ciselnik/cis_skl/`;

export const objednavkyUrl = serverUrl + `api/bberp/objednavky/`;

export const objednavky_typERP_OUrl = serverUrl + `api/bberp/objednavkyOstatne`;

export const objednavky_typERP_BBUrl = serverUrl + `api/bberp/objednavkyBB`;

export const objednavky_typERP_O_zapisUrl = serverUrl + `api/bberp/objednavkyOstatne/zapis`;

export const objednavky_typERP_BB_zapisUrl = serverUrl + `api/bberp/objednavkyBB/zapis`;

export const objednavky_typERP_O_objednavkaUrl = serverUrl + `api/bberp/objednavkyOstatne/objednavka`;

export const objednavky_typERP_BB_objednavkaUrl = serverUrl + `api/bberp/objednavkyBB/objednavka`;

export const putTagyUrl = serverUrl + `api/bberp/tag`;

export const postTagyPolozkyUrl = serverUrl + `api/bberp/zapis/tag`;

export const dodavatelUrl = serverUrl + `api/bberp/sklad/extdod/ico/`;

export const exDodSurovinyUrl = serverUrl + `api/bberp/sklad/extdod/suroviny/`;

export const postExDodSurovinyUrl = serverUrl + `api/bberp/sklad/extdod/`;

export const stavSkladuUrl = serverUrl + `api/bberp/sklad/aktstav/`;

export const prevodSurovinUrl = serverUrl + `api/bberp/sklad/prevods/suroviny/`;

export const prevodSurovinPostUrl = serverUrl + `api/bberp/sklad/prevods/`;

export const nabidnoutJinePobockeUrl = serverUrl + `api/bberp/sklad/prevods/suroviny/`;

export const kalendarUrl = serverUrl + `api/bberp/admin/otvhodkalendar/`;

export const otvSchvalenieUrl = serverUrl + `api/bberp/admin/otvhodSchvalenie`;

export const dokladyUrl = serverUrl + `api/bberp/sklad/prijem/doklady`;

export const prijemPolozkyUrl = serverUrl + `api/bberp/sklad/prijem/polozky`;

export const pdfUrl = serverUrl + `api/system/print/`;

export const sestavyUrl = serverUrl + `api/system/printlog/`;

export const HtmlUrl = serverUrl + `api/system/view/`;

export const EvidenceUrl = serverUrl + `api/bberp/data`;

export const PanelUrl = serverUrl + `api/bberp/paneledit`;

export const paneldefUrl = serverUrl + `api/bberp/paneldef`;

export const menuUrl = serverUrl + `api/bberp/menu`;

export const menuOblubeneUrl = serverUrl + `api/bberp/nastavmenuoblubene`;

export const menuRunUrl = serverUrl + `api/bberp/menurun`;

export const formRunUrl = serverUrl + `api/bberp/formrun`;

export const validationUrl = serverUrl + `api/bberp/datavalid`;

export const validationPanelUrl = serverUrl + `api/bberp/panelvalid`;

export const validationFormUrl = serverUrl + `api/bberp/formvalid`;

export const deletePanelUrl = serverUrl + `api/bberp/paneldelete`;

export const formSaveUrl = serverUrl + `api/bberp/formsave`;

export const panelSaveUrl = serverUrl + `api/bberp/panelsave`;

export const panelEnterUrl = serverUrl + `api/bberp/panelEnter`;

export const Info_o_smeneUrl = serverUrl + `api/user/datumvyroba/`;

export const dajblubeneUrl = serverUrl + `api/bberp/dajoblubene/`;

export const prijemsUrl = serverUrl + `api/bberp/sklad/prijems`;

export const vsetkyNotifikacieUrl = serverUrl + `api/notifications/all`;

export const neprecitaneNotifikacieUrl = serverUrl + `api/notifications/unseen`;

export const precitaneNotifikacieUrl = serverUrl + `api/notifications/precitane`;

export const ObjednavkyOstatneUrl = serverUrl + `api/objOstatne/zoznam`;

export const ObjednavkyOstatneDetailUrl = serverUrl + `api/objOstatne/detail`;

//export const ObjednavkyOstatneObjednavkaUrl = serverUrl + `api/objOstatne/objednavka`;

export const ObjednavkyBBUrl = serverUrl + `api/objBB/zoznam`;

export const ObjednavkyBBDetailUrl = serverUrl + `api/objBB/detail`;

//export const ObjednavkyBBObjednavkaUrl = serverUrl + `api/objBB/objednavka`;

export const sendNotificationUrl = serverUrl + `api/notifications/sendNotification`;

export const notifikaciaUrl = serverUrl + `hubs/notification`;

export const zoznamObjednanychPoloziekExtUrl = serverUrl + `api/ext/zoznamPoloziek`;

export const zoznamReklamaciiBBUrl = serverUrl + `api/rekl/bb/zoznamReklamacii`;

export const zapisReklamaciiBBUrl = serverUrl + `api/rekl/bb/zapisReklamacii`;

export const zmenaHeslaUrl = serverUrl + `api/user/zmenaHesla`;

export const objMenuAkciaUrl = serverUrl + `api/objOstatne/menu_akcia`;

export const objMenuAkciaBBUrl = serverUrl + `api/objBB/menu_akcia`;

export const reklamacieIntUrl = serverUrl + `api/int/zoznamReklamacii`;

export const trellisReklamacieUrl = serverUrl + `api/trellis/reklamacie`;

export const trellisdaj_custom_fieldsUrl = serverUrl + `api/trellis/daj_custom_fields`;

export const VypisDokladuHTMLUrl = serverUrl + `api/ext/VypisDokladuHTML`;

export const VypisDokladuBBHTMLUrl = serverUrl + `api/rekl/bb/VypisDokladuHTML`;

export const VypisDokladuHTMLObjOstUrl = serverUrl + `api/objOstatne/VypisDokladuHTML`;

export const VypisDokladuPDFObjOstUrl = serverUrl + `api/objOstatne/VypisDokladuPDF`;

export const VypisDokladuHTMLObjBBUrl = serverUrl + `api/objBB/VypisDokladuHTML`;

export const VypisDokladuPDFObjBBUrl = serverUrl + `api/objBB/VypisDokladuPDF`;

export const removeAllConnUrl = serverUrl + `api/conn/removeAll`;

export const nacitajKosikObjOstatneUrl = serverUrl + `api/objOstatne/kosik`;

export const nacitajKosikObjBBUrl = serverUrl + `api/objBB/kosik`;
 
export const zoznamAktivitUrl = serverUrl + `api/user/zoznamAktivit`;

export const nulovanieKosikaUrl = serverUrl + `api/objOstatne/nulovanieKosika`;

//export const aktualizaciaKosikaUrl = serverUrl + `aapi/objOstatne/kosik`;

export const kopiaObjednavkyUrl = serverUrl + `api/objOstatne/kopia`;

export const nulovanieKosikaBBUrl = serverUrl + `api/objBB/nulovanieKosika`;

export const aktualizaciaKosikaBBUrl = serverUrl + `api/objBB/aktualizujKosik`;

export const kopiaObjednavkyBBUrl = serverUrl + `api/objBB/kopia`;

export const kopiaZapisObjednavkyUrl = serverUrl + `api/bberp/objednavkyOstatne/zapisKopia`;

export const kopiaZapisObjednavkyBBUrl = serverUrl + `api/bberp/objednavkyBB/zapisKopia`;

export const filesUploadUrl = serverUrl + `api/trellis/upload`;

export const zoznamReklamaciTrellisUrl = serverUrl + `api/trellis/zoznamReklamaci`;

export const presunPoloziekObjednavkyUrl = serverUrl + `api/objOstatne/presunPoloziek`;

export const presunPoloziekObjednavkyZapisUrl = serverUrl + `api/bberp/objednavkyOstatne/PresunPoloziek`;

export const presunPoloziekObjednavkyBBUrl = serverUrl + `api/objBB/presunPoloziek`;

export const presunPoloziekObjednavkyZapisBBUrl = serverUrl + `api/bberp/objednavkyBB/PresunPoloziek`;

export const errorLogUrl = serverUrl + `api/error/log`;

//reklamaceSQL

export const reklamacieSQLSuroviny = serverUrl + `api/reklamaceSQL/zoznamPoloziek/suroviny`;

export const reklamacieSQLDoklady = serverUrl + `api/reklamaceSQL/zoznamPoloziek/doklady`;

export const reklamacieSQLZapisUrl = serverUrl + `api/reklamaceSQL/zapisReklamacii`;

export const zoznamReklamaciiSQLUrl = serverUrl + `api/reklamaceSQL/zoznamReklamacii`;

export const detailReklamaciiSQLUrl = serverUrl + `api/reklamaceSQL/detailReklamacii`;

export const VypisDokladuHTMLReklamaciiSQLUrl = serverUrl + `api/reklamaceSQL/VypisDokladuHTML`;

export const zoznamReklamaciiSQLOtvoreneUrl = serverUrl + `api/reklamaceSQL/riesitel/zoznamReklamacii/otvorene`;

export const zoznamReklamaciiSQLUzavreteUrl = serverUrl + `api/reklamaceSQL/riesitel/zoznamReklamacii/uzavrete`;

export const reklMenuAkciaUrl = serverUrl + `api/reklamaceSQL/menu_akcia`;

export const zapisRieseniaReklamaceUrl = serverUrl + `api/reklamaceSQL/zapisRieseniaReklamace`;

export const stornoReklamaceUrl = serverUrl + `api/reklamaceSQL/stornoReklamace`;

export const filesUploadReklamaceUrl = serverUrl + `api/reklamaceSQL/priloha/upload/`;

export const jeSurovinaStrediskaUrl = serverUrl + `api/reklamaceSQL/jeSurovinaStrediska`;

export const reklamacieSQLStavSkl = serverUrl + `api/reklamaceSQL/zoznamPoloziek/stav_skladu`;




